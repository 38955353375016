export const meetingConstants = {
    CHECK_MEETING_REQUEST: "CASE_REQUEST_REQUEST",
    CHECK_MEETING_SUCCESS: "CASE_REQUEST_SUCCESS",
    CHECK_MEETING_FAILURE: "CASE_REQUEST_FAILURE",

    END_MEETING_REQUEST: "END_REQUEST_REQUEST",
    END_MEETING_SUCCESS: "END_REQUEST_SUCCESS",
    END_MEETING_FAILURE: "END_REQUEST_FAILURE",

    CLEAR_ERRORS: "CLEAR_ERRORS",
};
