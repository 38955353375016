export const adminConstants = {
  // Personal Info's Constants
  FETCH_PERSONAL_INFO_REQUEST: "FETCH_PERSONAL_INFO_REQUEST",
  FETCH_PERSONAL_INFO_SUCCESS: "FETCH_PERSONAL_INFO_SUCCESS",
  FETCH_PERSONAL_INFO_FAILURE: "FETCH_PERSONAL_INFO_FAILURE",

  UPDATE_PERSONAL_INFO_REQUEST: "UPDATE_PERSONAL_INFO_REQUEST",
  UPDATE_PERSONAL_INFO_SUCCESS: "UPDATE_PERSONAL_INFO_SUCCESS",
  UPDATE_PERSONAL_INFO_FAILURE: "UPDATE_PERSONAL_INFO_FAILURE",

  // Practice' Constants
  FETCH_PRACTICE_REQUEST: "FETCH_PRACTICE_REQUEST",
  FETCH_PRACTICE_SUCCESS: "FETCH_PRACTICE_SUCCESS",
  FETCH_PRACTICE_FAILURE: "FETCH_PRACTICE_FAILURE",

  // ? Chart Data
  FETCH_CHART_DATA_REQUEST: "FETCH_CHART_DATA_REQUEST",
  FETCH_CHART_DATA_SUCCESS: "FETCH_CHART_DATA_SUCCESS",
  FETCH_CHART_DATA_FAILURE: "FETCH_CHART_DATA_FAILURE",

  ADD_PRACTICE_REQUEST: "ADD_PRACTICE_REQUEST",
  ADD_PRACTICE_SUCCESS: "ADD_PRACTICE_SUCCESS",
  ADD_PRACTICE_FAILURE: "ADD_PRACTICE_FAILURE",

  UPDATE_PRACTICE_REQUEST: "UPDATE_PRACTICE_REQUEST",
  UPDATE_PRACTICE_SUCCESS: "UPDATE_PRACTICE_SUCCESS",
  UPDATE_PRACTICE_FAILURE: "UPDATE_PRACTICE_FAILURE",

  DELETE_PRACTICE_REQUEST: "DELETE_PRACTICE_REQUEST",
  DELETE_PRACTICE_SUCCESS: "DELETE_PRACTICE_SUCCESS",
  DELETE_PRACTICE_FAILURE: "DELETE_PRACTICE_FAILURE",

  //   Jurisdictions' Constants
  FETCH_JURISDICTIONS_REQUEST: "FETCH_JURISDICTIONS_REQUEST",
  FETCH_JURISDICTIONS_SUCCESS: "FETCH_JURISDICTIONS_SUCCESS",
  FETCH_JURISDICTIONS_FAILURE: "FETCH_JURISDICTIONS_FAILURE",

  ADD_JURISDICTION_REQUEST: "ADD_JURISDICTION_REQUEST",
  ADD_JURISDICTION_SUCCESS: "ADD_JURISDICTION_SUCCESS",
  ADD_JURISDICTION_FAILURE: "ADD_JURISDICTION_FAILURE",

  UPDATE_JURISDICTION_REQUEST: "UPDATE_JURISDICTION_REQUEST",
  UPDATE_JURISDICTION_SUCCESS: "UPDATE_JURISDICTION_SUCCESS",
  UPDATE_JURISDICTION_FAILURE: "UPDATE_JURISDICTION_FAILURE",

  DELETE_JURISDICTION_REQUEST: "DELETE_JURISDICTION_REQUEST",
  DELETE_JURISDICTION_SUCCESS: "DELETE_JURISDICTION_SUCCESS",
  DELETE_JURISDICTION_FAILURE: "DELETE_JURISDICTION_FAILURE",

  // Moderators' Constants
  FETCH_MODERATORS_REQUEST: "FETCH_MODERATORS_REQUEST",
  FETCH_MODERATORS_SUCCESS: "FETCH_MODERATORS_SUCCESS",
  FETCH_MODERATORS_FAILURE: "FETCH_MODERATORS_FAILURE",

  ADD_MODERATOR_REQUEST: "ADD_MODERATOR_REQUEST",
  ADD_MODERATOR_SUCCESS: "ADD_MODERATOR_SUCCESS",
  ADD_MODERATOR_FAILURE: "ADD_MODERATOR_FAILURE",

  UPDATE_MODERATOR_REQUEST: "UPDATE_MODERATOR_REQUEST",
  UPDATE_MODERATOR_SUCCESS: "UPDATE_MODERATOR_SUCCESS",
  UPDATE_MODERATOR_FAILURE: "UPDATE_MODERATOR_FAILURE",

  DELETE_MODERATOR_REQUEST: "DELETE_MODERATOR_REQUEST",
  DELETE_MODERATOR_SUCCESS: "DELETE_MODERATOR_SUCCESS",
  DELETE_MODERATOR_FAILURE: "DELETE_MODERATOR_FAILURE",

  // Lawyers' Constants
  FETCH_LAWYERS_REQUEST: "FETCH_LAWYERS_REQUEST",
  FETCH_LAWYERS_SUCCESS: "FETCH_LAWYERS_SUCCESS",
  FETCH_LAWYERS_FAILURE: "FETCH_LAWYERS_FAILURE",

  ADD_LAWYER_REQUEST: "ADD_LAWYER_REQUEST",
  ADD_LAWYER_SUCCESS: "ADD_LAWYER_SUCCESS",
  ADD_LAWYER_FAILURE: "ADD_LAWYER_FAILURE",

  UPDATE_LAWYER_REQUEST: "UPDATE_LAWYER_REQUEST",
  UPDATE_LAWYER_SUCCESS: "UPDATE_LAWYER_SUCCESS",
  UPDATE_LAWYER_FAILURE: "UPDATE_LAWYER_FAILURE",

  DELETE_LAWYER_REQUEST: "DELETE_LAWYER_REQUEST",
  DELETE_LAWYER_SUCCESS: "DELETE_LAWYER_SUCCESS",
  DELETE_LAWYER_FAILURE: "DELETE_LAWYER_FAILURE",
  // admin dashboard
  GET_DASHBOARD_REQUEST: "GET_DASHBOARD_REQUEST",
  GET_DASHBOARD_SUCCESS: "GET_DASHBOARD_SUCCESS",
  GET_DASHBOARD_FAILURE: "GET_DASHBOARD_FAILURE",

  FETCH_FAQS_REQUEST: "FETCH_FAQS_REQUEST",
  FETCH_FAQS_SUCCESS: "FETCH_FAQS_SUCCESS",
  FETCH_FAQS_FAILURE: "FETCH_FAQS_FAILURE",

  DELETE_FAQS_REQUEST: "DELETE_FAQS_REQUEST",
  DELETE_FAQS_SUCCESS: "DELETE_FAQS_SUCCESS",
  DELETE_FAQS_FAILURE: "DELETE_FAQS_FAILURE",

  UPDATE_FAQS_REQUEST: "UPDATE_FAQS_REQUEST",
  UPDATE_FAQS_SUCCESS: "UPDATE_FAQS_SUCCESS",
  UPDATE_FAQS_FAILURE: "UPDATE_FAQS_FAILURE",

  DETAIL_BY_FAQS_REQUEST: "DETAIL_BY_FAQS_REQUEST",
  DETAIL_BY_FAQS_SUCCESS: "DETAIL_BY_FAQS_SUCCESS",
  DETAIL_BY_FAQS_FAILURE: "DETAIL_BY_FAQS_FAILURE",

  CREATE_FAQS_REQUEST: "CREATE_FAQS_REQUEST",
  CREATE_FAQS_SUCCESS: "CREATE_FAQS_SUCCESS",
  CREATE_FAQS_FAILURE: "CREATE_FAQS_FAILURE",

  // CLients' Constants
  FETCH_CLIENTS_REQUEST: "FETCH_CLIENTS_REQUEST",
  FETCH_CLIENTS_SUCCESS: "FETCH_CLIENTS_SUCCESS",
  FETCH_CLIENTS_FAILURE: "FETCH_CLIENTS_FAILURE",

  ADD_CLIENT_REQUEST: "ADD_CLIENT_REQUEST",
  ADD_CLIENT_SUCCESS: "ADD_CLIENT_SUCCESS",
  ADD_CLIENT_FAILURE: "ADD_CLIENT_FAILURE",

  UPDATE_CLIENT_REQUEST: "UPDATE_CLIENT_REQUEST",
  UPDATE_CLIENT_SUCCESS: "UPDATE_CLIENT_SUCCESS",
  UPDATE_CLIENT_FAILURE: "UPDATE_CLIENT_FAILURE",

  DELETE_CLIENT_REQUEST: "DELETE_CLIENT_REQUEST",
  DELETE_CLIENT_SUCCESS: "DELETE_CLIENT_SUCCESS",
  DELETE_CLIENT_FAILURE: "DELETE_CLIENT_FAILURE",

  CONTRACT_LIST_REQUEST: "CONTRACT_LIST_REQUEST",
  CONTRACT_LIST_SUCCESS: "CONTRACT_LIST_SUCCESS",
  CONTRACT_LIST_FAILURE: "CONTRACT_LIST_FAILURE",

  CONTRACT_BY_ID_REQUEST: "CONTRACT_BY_ID_REQUEST",
  CONTRACT_BY_ID_SUCCESS: "CONTRACT_BY_ID_SUCCESS",
  CONTRACT_BY_ID_FAILURE: "CONTRACT_BY_ID_FAILURE",

  ALL_COUNTRIES_FOR_ADMIN_REQUEST: "ALL_COUNTRIES_FOR_ADMIN_REQUEST",
  ALL_COUNTRIES_FOR_ADMIN_SUCCESS: "ALL_COUNTRIES_FOR_ADMIN_SUCCESS",
  ALL_COUNTRIES_FOR_ADMIN_FAILURE: "ALL_COUNTRIES_FOR_ADMIN_FAILURE",

  UPDATE_COUNTRY_FOR_ADMIN_REQUEST: "UPDATE_COUNTRY_FOR_ADMIN_REQUEST",
  UPDATE_COUNTRY_FOR_ADMIN_SUCCESS: "UPDATE_COUNTRY_FOR_ADMIN_SUCCESS",
  UPDATE_COUNTRY_FOR_ADMIN_FAILURE: "UPDATE_COUNTRY_FOR_ADMIN_FAILURE",

  WEB_SETTING_REQUEST: "WEB_SETTING_REQUEST",
  WEB_SETTING_SUCCESS: "WEB_SETTING_SUCCESS",
  WEB_SETTING_FAILURE: "WEB_SETTING_FAILURE",

  WEB_DETAILS_REQUEST: "WEB_DETAILS_REQUEST",
  WEB_DETAILS_SUCCESS: "WEB_DETAILS_SUCCESS",
  WEB_DETAILS_FAILURE: "WEB_DETAILS_FAILURE",

  FETCH_WITHDRAW_REQUEST_LIST_REQUEST: "FETCH_WITHDRAW_REQUEST_LIST_REQUEST",
  FETCH_WITHDRAW_REQUEST_LIST_SUCCESS: "FETCH_WITHDRAW_REQUEST_LIST_SUCCESS",
  FETCH_WITHDRAW_REQUEST_LIST_FAILURE: "FETCH_WITHDRAW_REQUEST_LIST_FAILURE",

  UPDATE_REQUEST_REQUEST: "UPDATE_REQUEST_REQUEST",
  UPDATE_REQUEST_SUCCESS: "UPDATE_REQUEST_SUCCESS",
  UPDATE_REQUEST_FAILURE: "UPDATE_REQUEST_FAILURE",

  FETCH_CONTACT_US_MESSAGES_REQUEST: "FETCH_CONTACT_US_MESSAGES_REQUEST",
  FETCH_CONTACT_US_MESSAGES_SUCCESS: "FETCH_CONTACT_US_MESSAGES_SUCCESS",
  FETCH_CONTACT_US_MESSAGES_FAILURE: "FETCH_CONTACT_US_MESSAGES_FAILURE",

  NEW_CONTACT_US_MESSAGES_REQUEST: "NEW_CONTACT_US_MESSAGES_REQUEST",
  NEW_CONTACT_US_MESSAGES_SUCCESS: "NEW_CONTACT_US_MESSAGES_SUCCESS",
  NEW_CONTACT_US_MESSAGES_FAILURE: "NEW_CONTACT_US_MESSAGES_FAILURE",

  RESPOND_MESSAGE_REQUEST: "RESPOND_MESSAGE_REQUEST",
  RESPOND_MESSAGE_SUCCESS: "RESPOND_MESSAGE_SUCCESS",
  RESPOND_MESSAGE_FAILURE: "RESPOND_MESSAGE_FAILURE",

  DELETE_MESSAGE_REQUEST: "DELETE_MESSAGE_REQUEST",
  DELETE_MESSAGE_SUCCESS: "DELETE_MESSAGE_SUCCESS",
  DELETE_MESSAGE_FAILURE: "DELETE_MESSAGE_FAILURE",

  FETCH_NEWSLETTER_REQUEST: "FETCH_NEWSLETTER_REQUEST",
  FETCH_NEWSLETTER_SUCCESS: "FETCH_NEWSLETTER_SUCCESS",
  FETCH_NEWSLETTER_FAILURE: "FETCH_NEWSLETTER_FAILURE",

  FETCH_USERSTATISTICS_REQUEST: "FETCH_USERSTATISTICS_REQUEST",
  FETCH_USERSTATISTICS_SUCCESS: "FETCH_USERSTATISTICS_SUCCESS",
  FETCH_USERSTATISTICS_FAILURE: "FETCH_USERSTATISTICS_FAILURE",

  REORDER_FAQS_REQUEST: "REORDER_FAQS_REQUEST",
  REORDER_FAQS_SUCCESS: "REORDER_FAQS_SUCCESS",
  REORDER_FAQS_FAILURE: "REORDER_FAQS_FAILURE",

  FETCH_CAROUSELS_REQUEST: "FETCH_CAROUSELS_REQUEST",
  FETCH_CAROUSELS_SUCCESS: "FETCH_CAROUSELS_SUCCESS",
  FETCH_CAROUSELS_FAILURE: "FETCH_CAROUSELS_FAILURE",

  ADD_AN_IMAGE_OF_CAROSEL_REQUEST: "ADD_AN_IMAGE_OF_CAROSEL_REQUEST",
  ADD_AN_IMAGE_OF_CAROSEL_SUCCESS: "ADD_AN_IMAGE_OF_CAROSEL_SUCCESS",
  ADD_AN_IMAGE_OF_CAROSEL_FAILURE: "ADD_AN_IMAGE_OF_CAROSEL_FAILURE",

  ADD_CAROUSEL_TEXT_REQUEST: "ADD_CAROUSEL_TEXT_REQUEST",
  ADD_CAROUSEL_TEXT_SUCCESS: "ADD_CAROUSEL_TEXT_SUCCESS",
  ADD_CAROUSEL_TEXT_FAILURE: "ADD_CAROUSEL_TEXT_FAILURE",

  UPDATE_CAROUSEL_TEXT_REQUEST: "UPDATE_CAROUSEL_TEXT_REQUEST",
  UPDATE_CAROUSEL_TEXT_SUCCESS: "UPDATE_CAROUSEL_TEXT_SUCCESS",
  UPDATE_CAROUSEL_TEXT_FAILURE: "UPDATE_CAROUSEL_TEXT_FAILURE",

  REORDER_CAROUSEL_REQUEST: "REORDER_CAROUSEL_REQUEST",
  REORDER_CAROUSEL_SUCCESS: "REORDER_CAROUSEL_SUCCESS",
  REORDER_CAROUSEL_FAILURE: "REORDER_CAROUSEL_FAILURE",

  DELETE_AN_IMAGE_OF_CAROUSEL_REQUEST: "DELETE_AN_IMAGE_OF_CAROUSEL_REQUEST",
  DELETE_AN_IMAGE_OF_CAROUSEL_SUCCESS: "DELETE_AN_IMAGE_OF_CAROUSEL_SUCCESS",
  DELETE_AN_IMAGE_OF_CAROUSEL_FAILURE: "DELETE_AN_IMAGE_OF_CAROUSEL_FAILURE",

  CLEAR_ERRORS: "CLEAR_ERRORS",
};
