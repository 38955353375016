import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
// import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyATqfbQ7GaPzSETEpolGaMnsnPUiw3jWDE",
  authDomain: "dotclick-8b8c8.firebaseapp.com",
  projectId: "dotclick-8b8c8",
  storageBucket: "dotclick-8b8c8.appspot.com",
  messagingSenderId: "50690314993",
  appId: "1:50690314993:web:1890c874073518f610cf50",
  measurementId: "G-DKS2JG0LJF",
};

const app = initializeApp(firebaseConfig);
const provider = new GoogleAuthProvider();
const providerFacebook = new FacebookAuthProvider();
//   const analytics = getAnalytics(app);
const auth = getAuth(app);

export { auth, provider, providerFacebook };
