export const clientConstants = {
  GET_CLIENT_DASHBOARD_REQUEST: "GET_CLIENT_DASHBOARD_REQUEST",
  GET_CLIENT_DASHBOARD_SUCCESS: "GET_CLIENT_DASHBOARD_SUCCESS",
  GET_CLIENT_DASHBOARD_FAILURE: "GET_CLIENT_DASHBOARD_FAILURE",

  GET_CLIENT_TIME_SLOT_REQUEST: "GET_CLIENT_TIME_SLOT_REQUEST",
  GET_CLIENT_TIME_SLOT_SUCCESS: "GET_CLIENT_TIME_SLOT_SUCCESS",
  GET_CLIENT_TIME_SLOT_FAILURE: "GET_CLIENT_TIME_SLOT_FAILURE",

  GET_LAWYER_AVAILIBILITY_REQUEST: "GET_LAWYER_AVAILIBILITY_REQUEST",
  GET_LAWYER_AVAILIBILITY_SUCCESS: "GET_LAWYER_AVAILIBILITY_SUCCESS",
  GET_LAWYER_AVAILIBILITY_FAILURE: "GET_LAWYER_AVAILIBILITY_FAILURE",

  BOOK_APPOINTMENT_REQUEST: "BOOK_APPOINTMENT_REQUEST",
  BOOK_APPOINTMENT_SUCCESS: "BOOK_APPOINTMENT_SUCCESS",
  BOOK_APPOINTMENT_FAILURE: "BOOK_APPOINTMENT_FAILURE",

  CONTRACT_LIST_REQUEST: "CONTRACT_LIST_REQUEST",
  CONTRACT_LIST_SUCCESS: "CONTRACT_LIST_SUCCESS",
  CONTRACT_LIST_FAILURE: "CONTRACT_LIST_FAILURE",

  CONTRACT_BY_ID_REQUEST: "CONTRACT_BY_ID_REQUEST",
  CONTRACT_BY_ID_SUCCESS: "CONTRACT_BY_ID_SUCCESS",
  CONTRACT_BY_ID_FAILURE: "CONTRACT_BY_ID_FAILURE",

  PAYMENT_OF_CONTRACT_REQUEST: "PAYMENT_OF_CONTRACT_REQUEST",
  PAYMENT_OF_CONTRACT_SUCCESS: "PAYMENT_OF_CONTRACT_SUCCESS",
  PAYMENT_OF_CONTRACT_FAILURE: "PAYMENT_OF_CONTRACT_FAILURE",

  APPROVE_CONTRACT_REQUEST: "APPROVE_CONTRACT_REQUEST",
  APPROVE_CONTRACT_SUCCESS: "APPROVE_CONTRACT_SUCCESS",
  APPROVE_CONTRACT_FAILURE: "APPROVE_CONTRACT_FAILURE",

  ACCEPT_CONTRACT_REQUEST: "ACCEPT_CONTRACT_REQUEST",
  ACCEPT_CONTRACT_SUCCESS: "ACCEPT_CONTRACT_SUCCESS",
  ACCEPT_CONTRACT_FAILURE: "ACCEPT_CONTRACT_FAILURE",

  APPOINTMENT_LIST_REQUEST: "APPOINTMENT_LIST_REQUEST",
  APPOINTMENT_LIST_SUCCESS: "APPOINTMENT_LIST_SUCCESS",
  APPOINTMENT_LIST_FAILURE: "APPOINTMENT_LIST_FAILURE",

  VIEW_MILESTONE_STAGES_REQUEST: "VIEW_MILESTONE_STAGES_REQUEST",
  VIEW_MILESTONE_STAGES_SUCCESS: "VIEW_MILESTONE_STAGES_SUCCESS",
  VIEW_MILESTONE_STAGES_FAILURE: "VIEW_MILESTONE_STAGES_FAILURE",

  VIEW_MILESTONE_STEPS_REQUEST: "VIEW_MILESTONE_STEPS_REQUEST",
  VIEW_MILESTONE_STEPS_SUCCESS: "VIEW_MILESTONE_STEPS_SUCCESS",
  VIEW_MILESTONE_STEPS_FAILURE: "VIEW_MILESTONE_STEPS_FAILURE",

  IS_ABLE_TO_CHAT_REQUEST: "IS_ABLE_TO_CHAT_REQUEST",
  IS_ABLE_TO_CHAT_SUCCESS: "IS_ABLE_TO_CHAT_SUCCESS",
  IS_ABLE_TO_CHAT_FAILURE: "IS_ABLE_TO_CHAT_FAILURE",
};
