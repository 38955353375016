export const caseConstants = {
  FETCH_CASES_REQUEST: "FETCH_CASES_REQUEST",
  FETCH_CASES_SUCCESS: "FETCH_CASES_SUCCESS",
  FETCH_CASES_FAILURE: "FETCH_CASES_FAILURE",

  SUBMIT_CASE_REQUEST: "SUBMIT_CASE_REQUEST",
  SUBMIT_CASE_SUCCESS: "SUBMIT_CASE_SUCCESS",
  SUBMIT_CASE_FAILURE: "SUBMIT_CASE_FAILURE",

  CASE_REQUEST: "CASE_REQUEST",
  CASE_REQUEST_SUCCESS: "CASE_REQUEST_SUCCESS",
  CASE_REQUEST_FAILURE: "CASE_REQUEST_FAILURE",

  CLEAR_ERRORS: "CLEAR_ERRORS",
};
