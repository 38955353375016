export const notificationsConstants = {
  GET_NOTIFICATIONS_REQUEST: "GET_NOTIFICATIONS_REQUEST",
  GET_NOTIFICATIONS_SUCCESS: "GET_NOTIFICATIONS_SUCCESS",
  GET_NOTIFICATIONS_FAILURE: "GET_NOTIFICATIONS_FAILURE",

  DELETE_NOTIFICATION_REQUEST: "DELETE_NOTIFICATION_REQUEST",
  DELETE_NOTIFICATION_SUCCESS: "DELETE_NOTIFICATION_SUCCESS",
  DELETE_NOTIFICATION_FAILURE: "DELETE_NOTIFICATION_FAILURE",

  READ_NOTIFICATION_REQUEST: "READ_NOTIFICATION_REQUEST",
  READ_NOTIFICATION_SUCCESS: "READ_NOTIFICATION_SUCCESS",
  READ_NOTIFICATION_FAILURE: "READ_NOTIFICATION_FAILURE",

  COUNT_NOTIFICATION_REQUEST: "COUNT_NOTIFICATION_REQUEST",
  COUNT_NOTIFICATION_SUCCESS: "COUNT_NOTIFICATION_SUCCESS",
  COUNT_NOTIFICATION_FAILURE: "COUNT_NOTIFICATION_FAILURE",

  CLEAR_ERRORS: "CLEAR_ERRORS",
};
