export const lawyerConstants = {
  LAWYER_AVAILIBILITY_REQUEST: "LAWYER_AVAILIBILITY_REQUEST",
  LAWYER_AVAILIBILITY_SUCCESS: "LAWYER_AVAILIBILITY_SUCCESS",
  LAWYER_AVAILIBILITY_FAILURE: "LAWYER_AVAILIBILITY_FAILURE",

  GET_LAWYER_AVAILIBILITY_REQUEST: "GET_LAWYER_AVAILIBILITY_REQUEST",
  GET_LAWYER_AVAILIBILITY_SUCCESS: "GET_LAWYER_AVAILIBILITY_SUCCESS",
  GET_LAWYER_AVAILIBILITY_FAILURE: "GET_LAWYER_AVAILIBILITY_FAILURE",

  UPDATE_LAWYER_AVAILIBILITY_REQUEST: "UPDATE_LAWYER_AVAILIBILITY_REQUEST",
  UPDATE_LAWYER_AVAILIBILITY_SUCCESS: "UPDATE_LAWYER_AVAILIBILITY_SUCCESS",
  UPDATE_LAWYER_AVAILIBILITY_FAILURE: "UPDATE_LAWYER_AVAILIBILITY_FAILURE",

  GET_LAWYER_CASES_REQUEST: "GET_LAWYER_CASES_REQUEST",
  GET_LAWYER_CASES_SUCCESS: "GET_LAWYER_CASES_SUCCESS",
  GET_LAWYER_CASES_FAILURE: "GET_LAWYER_CASES_FAILURE",

  GET_TIME_SLOT_REQUEST: "GET_TIME_SLOT_REQUEST",
  GET_TIME_SLOT_SUCCESS: "GET_TIME_SLOT_SUCCESS",
  GET_TIME_SLOT_FAILURE: "GET_TIME_SLOT_FAILURE",

  GENERATE_TIME_SLOT_REQUEST: "GENERATE_TIME_SLOT_REQUEST",
  GENERATE_TIME_SLOT_SUCCESS: "GENERATE_TIME_SLOT_SUCCESS",
  GENERATE_TIME_SLOT_FAILURE: "GENERATE_TIME_SLOT_FAILURE",

  CONTRACT_CREATION_REQUEST: "CONTRACT_CREATION_REQUEST",
  CONTRACT_CREATION_SUCCESS: "CONTRACT_CREATION_SUCCESS",
  CONTRACT_CREATION_FAILURE: "CONTRACT_CREATION_FAILURE",

  CLIENT_LIST_REQUEST: "CLIENT_LIST_REQUEST",
  CLIENT_LIST_SUCCESS: "CLIENT_LIST_SUCCESS",
  CLIENT_LIST_FAILURE: "CLIENT_LIST_FAILURE",

  CONTRACT_LIST_REQUEST: "CONTRACT_LIST_REQUEST",
  CONTRACT_LIST_SUCCESS: "CONTRACT_LIST_SUCCESS",
  CONTRACT_LIST_FAILURE: "CONTRACT_LIST_FAILURE",

  CONTRACT_BY_ID_REQUEST: "CONTRACT_BY_ID_REQUEST",
  CONTRACT_BY_ID_SUCCESS: "CONTRACT_BY_ID_SUCCESS",
  CONTRACT_BY_ID_FAILURE: "CONTRACT_BY_ID_FAILURE",

  REQUEST_CONTRACT_REQUEST: "REQUEST_CONTRACT_REQUEST",
  REQUEST_CONTRACT_SUCCESS: "REQUEST_CONTRACT_SUCCESS",
  REQUEST_CONTRACT_FAILURE: "REQUEST_CONTRACT_FAILURE",

  APPOINTMENT_LIST_REQUEST: "APPOINTMENT_LIST_REQUEST",
  APPOINTMENT_LIST_SUCCESS: "APPOINTMENT_LIST_SUCCESS",
  APPOINTMENT_LIST_FAILURE: "APPOINTMENT_LIST_FAILURE",

  TRANSACTION_LIST_REQUEST: "TRANSACTION_LIST_REQUEST",
  TRANSACTION_LIST_SUCCESS: "TRANSACTION_LIST_SUCCESS",
  TRANSACTION_LIST_FAILURE: "TRANSACTION_LIST_FAILURE",

  TRANSACTION_BY_ID_REQUEST: "TRANSACTION_BY_ID_REQUEST",
  TRANSACTION_BY_ID_SUCCESS: "TRANSACTION_BY_ID_SUCCESS",
  TRANSACTION_BY_ID_FAILURE: "TRANSACTION_BY_ID_FAILURE",

  WITHDRAW_REQUEST_LIST_REQUEST: "WITHDRAW_REQUEST_LIST_REQUEST",
  WITHDRAW_REQUEST_LIST_SUCCESS: "WITHDRAW_REQUEST_LIST_SUCCESS",
  WITHDRAW_REQUEST_LIST_FAILURE: "WITHDRAW_REQUEST_LIST_FAILURE",

  WITHDRAW_REQUEST_REQUEST: "WITHDRAW_REQUEST_REQUEST",
  WITHDRAW_REQUEST_SUCCESS: "WITHDRAW_REQUEST_SUCCESS",
  WITHDRAW_REQUEST_FAILURE: "WITHDRAW_REQUEST_FAILURE",

  CREATE_MILESTONE_STAGE_REQUEST: "CREATE_MILESTONE_STAGE_REQUEST",
  CREATE_MILESTONE_STAGE_SUCCESS: "CREATE_MILESTONE_STAGE_SUCCESS",
  CREATE_MILESTONE_STAGE_FAILURE: "CREATE_MILESTONE_STAGE_FAILURE",

  GET_MILESTONE_STAGE_REQUEST: "GET_MILESTONE_STAGE_REQUEST",
  GET_MILESTONE_STAGE_SUCCESS: "GET_MILESTONE_STAGE_SUCCESS",
  GET_MILESTONE_STAGE_FAILURE: "GET_MILESTONE_STAGE_FAILURE",

  CREATE_MILESTONE_STEP_REQUEST: "CREATE_MILESTONE_STEP_REQUEST",
  CREATE_MILESTONE_STEP_SUCCESS: "CREATE_MILESTONE_STEP_SUCCESS",
  CREATE_MILESTONE_STEP_FAILURE: "CREATE_MILESTONE_STEP_FAILURE",

  GET_MILESTONE_STEP_REQUEST: "GET_MILESTONE_STEP_REQUEST",
  GET_MILESTONE_STEP_SUCCESS: "GET_MILESTONE_STEP_SUCCESS",
  GET_MILESTONE_STEP_FAILURE: "GET_MILESTONE_STEP_FAILURE",

  UPDATE_IBAN_REQUEST: "UPDATE_IBAN_REQUEST",
  UPDATE_IBAN_SUCCESS: "UPDATE_IBAN_SUCCESS",
  UPDATE_IBAN_FAILURE: "UPDATE_IBAN_FAILURE",
};
