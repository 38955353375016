export const chatConstants = {
  CASE_REQUEST_REQUEST: "CASE_REQUEST_REQUEST",
  CASE_REQUEST_SUCCESS: "CASE_REQUEST_SUCCESS",
  CASE_REQUEST_FAILURE: "CASE_REQUEST_FAILURE",

  PEOPLE_CHAT_LIST_REQUEST: "PEOPLE_CHAT_LIST_REQUEST",
  PEOPLE_CHAT_LIST_SUCCESS: "PEOPLE_CHAT_LIST_SUCCESS",
  PEOPLE_CHAT_LIST_FAILURE: "PEOPLE_CHAT_LIST_FAILURE",

  GET_CHAT_LIST_BY_ID_REQUEST: "GET_CHAT_LIST_BY_ID_REQUEST",
  GET_CHAT_LIST_BY_ID_SUCCESS: "GET_CHAT_LIST_BY_ID_SUCCESS",
  GET_CHAT_LIST_BY_ID_FAILURE: "GET_CHAT_LIST_BY_ID_FAILURE",

  FETCH_MORE_MESSAGES_REQUEST: "FETCH_MORE_MESSAGES_REQUEST",
  FETCH_MORE_MESSAGES_SUCCESS: "FETCH_MORE_MESSAGES_SUCCESS",
  FETCH_MORE_MESSAGES_FAILURE: "FETCH_MORE_MESSAGES_FAILURE",

  ATTACHMENT_CHAT_LIST_REQUEST: "ATTACHMENT_CHAT_LIST_REQUEST",
  ATTACHMENT_CHAT_LIST_SUCCESS: "ATTACHMENT_CHAT_LIST_SUCCESS",
  ATTACHMENT_CHAT_LIST_FAILURE: "ATTACHMENT_CHAT_LIST_FAILURE",

  CREATE_CHAT_REQUEST: "CREATE_CHAT_REQUEST",
  CREATE_CHAT_SUCCESS: "CREATE_CHAT_SUCCESS",
  CREATE_CHAT_FAILURE: "CREATE_CHAT_FAILURE",

  TEST_CHAT_EVENT_REQUEST: "TEST_CHAT_EVENT_REQUEST",
  TEST_CHAT_EVENT_SUCCESS: "TEST_CHAT_EVENT_SUCCESS",
  TEST_CHAT_EVENT_FAILURE: "TEST_CHAT_EVENT_FAILURE",

  ATTACHMENT_LIST_REQUEST: "ATTACHMENT_LIST_REQUEST",
  ATTACHMENT_LIST_SUCCESS: "ATTACHMENT_LIST_SUCCESS",
  ATTACHMENT_LIST_FAILURE: "ATTACHMENT_LIST_FAILURE",

  FETCH_MESSAGES_REQUEST: "FETCH_MESSAGES_REQUEST",
  FETCH_MESSAGES_SUCCESS: "FETCH_MESSAGES_SUCCESS",
  FETCH_MESSAGES_FAILURE: "FETCH_MESSAGES_FAILURE",
  SET_CASE_STATUS: "SET_CASE_STATUS",

  SEND_MESSAGE_REQUEST: "SEND_MESSAGE_REQUEST",
  SEND_MESSAGE_SUCCESS: "SEND_MESSAGE_SUCCESS",
  SEND_MESSAGE_FAILURE: "SEND_MESSAGE_FAILURE",

  FETCH_UNREAD_MESSAGES_REQUEST: "FETCH_UNREAD_MESSAGES_REQUEST",
  FETCH_UNREAD_MESSAGES_SUCCESS: "FETCH_UNREAD_MESSAGES_SUCCESS",
  FETCH_UNREAD_MESSAGES_FAILURE: "FETCH_UNREAD_MESSAGES_FAILURE",
  
  OFFLINE_PEOPLE: "OFFLINE_PEOPLE",
  
  CLEAR_ERRORS: "CLEAR_ERRORS",
};
