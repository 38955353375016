export const webConstants = {
  // Personal Info's Constants
  FETCH_FEATURED_LAWYER_REQUEST: "FETCH_FEATURED_LAWYER_REQUEST",
  FETCH_FEATURED_LAWYER_SUCCESS: "FETCH_FEATURED_LAWYER_SUCCESS",
  FETCH_FEATURED_LAWYER_FAILURE: "FETCH_FEATURED_LAWYER_FAILURE",

  FIND_LAWYER_REQUEST: "FIND_LAWYER_REQUEST",
  FIND_LAWYER_SUCCESS: "FIND_LAWYER_SUCCESS",
  FIND_LAWYER_FAILURE: "FIND_LAWYER_FAILURE",

  FETCH_PRACTICE_REQUEST: "FETCH_PRACTICE_REQUEST",
  FETCH_PRACTICE_SUCCESS: "FETCH_PRACTICE_SUCCESS",
  FETCH_PRACTICE_FAILURE: "FETCH_PRACTICE_FAILURE",

  FETCH_JURISDICTIONS_REQUEST: "FETCH_JURISDICTIONS_REQUEST",
  FETCH_JURISDICTIONS_SUCCESS: "FETCH_JURISDICTIONS_SUCCESS",
  FETCH_JURISDICTIONS_FAILURE: "FETCH_JURISDICTIONS_FAILURE",

  GET_LAWYER_BY_ID_REQUEST: "GET_LAWYER_BY_ID_REQUEST",
  GET_LAWYER_BY_ID_SUCCESS: "GET_LAWYER_BY_ID_SUCCESS",
  GET_LAWYER_BY_ID_FAILURE: "GET_LAWYER_BY_ID_FAILURE",

  GET_JURISDICTION_BY_COUNTRY_REQUEST: "GET_JURISDICTION_BY_COUNTRY_REQUEST",
  GET_JURISDICTION_BY_COUNTRY_SUCCESS: "GET_JURISDICTION_BY_COUNTRY_SUCCESS",
  GET_JURISDICTION_BY_COUNTRY_FAILURE: "GET_JURISDICTION_BY_COUNTRY_FAILURE",

  GET_COUNTRIES_OF_JURISDICTIONS_REQUEST:
    "GET_COUNTRIES_OF_JURISDICTIONS_REQUEST",
  GET_COUNTRIES_OF_JURISDICTIONS_SUCCESS:
    "GET_COUNTRIES_OF_JURISDICTIONS_SUCCESS",
  GET_COUNTRIES_OF_JURISDICTIONS_FAILURE:
    "GET_COUNTRIES_OF_JURISDICTIONS_FAILURE",

  FETCH_WEB_DETAILS_REQUEST: "FETCH_WEB_DETAILS_REQUEST",
  FETCH_WEB_DETAILS_SUCCESS: "FETCH_WEB_DETAILS_SUCCESS",
  FETCH_WEB_DETAILS_FAILURE: "FETCH_WEB_DETAILS_FAILURE",

  SUBSCRIBE_REQUEST: "SUBSCRIBE_REQUEST",
  SUBSCRIBE_SUCCESS: "SUBSCRIBE_SUCCESS",
  SUBSCRIBE_FAILURE: "SUBSCRIBE_FAILURE",

  UNSUBSCRIBE_REQUEST: "UNSUBSCRIBE_REQUEST",
  UNSUBSCRIBE_SUCCESS: "UNSUBSCRIBE_SUCCESS",
  UNSUBSCRIBE_FAILURE: "UNSUBSCRIBE_FAILURE",

  POST_A_MESSAGE_REQUEST: "POST_A_MESSAGE_REQUEST",
  POST_A_MESSAGE_SUCCESS: "POST_A_MESSAGE_SUCCESS",
  POST_A_MESSAGE_FAILURE: "POST_A_MESSAGE_FAILURE",

  FETCH_FAQS_REQUEST: "FETCH_FAQS_REQUEST",
  FETCH_FAQS_SUCCESS: "FETCH_FAQS_SUCCESS",
  FETCH_FAQS_FAILURE: "FETCH_FAQS_FAILURE",

  FETCH_CAROUSELS_REQUEST: "FETCH_CAROUSELS_REQUEST",
  FETCH_CAROUSELS_SUCCESS: "FETCH_CAROUSELS_SUCCESS",
  FETCH_CAROUSELS_FAILURE: "FETCH_CAROUSELS_FAILURE",
};
