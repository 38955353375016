export const userConstants = {
  FETCH_PERSONAL_INFO_REQUEST: "FETCH_PERSONAL_INFO_REQUEST",
  FETCH_PERSONAL_INFO_SUCCESS: "FETCH_PERSONAL_INFO_SUCCESS",
  FETCH_PERSONAL_INFO_FAILURE: "FETCH_PERSONAL_INFO_FAILURE",

  UPDATE_PERSONAL_INFO_REQUEST: "UPDATE_PERSONAL_INFO_REQUEST",
  UPDATE_PERSONAL_INFO_SUCCESS: "UPDATE_PERSONAL_INFO_SUCCESS",
  UPDATE_PERSONAL_INFO_FAILURE: "UPDATE_PERSONAL_INFO_FAILURE",

  UPDATE_PROFILE_PICTURE_REQUEST: "UPDATE_PROFILE_PICTURE_REQUEST",
  UPDATE_PROFILE_PICTURE_SUCCESS: "UPDATE_PROFILE_PICTURE_SUCCESS",
  UPDATE_PROFILE_PICTURE_FAILURE: "UPDATE_PROFILE_PICTURE_FAILURE",

  CREATE_USER_REVIEW_REQUEST: "CREATE_USER_REVIEW_REQUEST",
  CREATE_USER_REVIEW_SUCCESS: "CREATE_USER_REVIEW_SUCCESS",
  CREATE_USER_REVIEW_FAILURE: "CREATE_USER_REVIEW_FAILURE",

  CHANGE_EMAIL_NOTIFICATION_REQUEST: "CHANGE_EMAIL_NOTIFICATION_REQUEST",
  CHANGE_EMAIL_NOTIFICATION_SUCCESS: "CHANGE_EMAIL_NOTIFICATION_SUCCESS",
  CHANGE_EMAIL_NOTIFICATION_FAILURE: "CHANGE_EMAIL_NOTIFICATION_FAILURE",

  CLEAR_ERRORS: "CLEAR_ERRORS",
};
